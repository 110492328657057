import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { useAuth } from '../contexts/AuthContext';
import { Header } from '../components/Layout/Header';
import ContentList from '../components/Layout/ContentList';
import useProjectForm from '../hooks/useProjectForm';
import { compressAndUploadImage } from '../utils/imageUtils';
import FloatingHeader from '../components/Layout/FloatingHeader';
import { ContentItem } from '../types/ContentItem';
import { Input } from "../components/ui/input"
import { Alert, AlertDescription } from "../components/ui/alert"
import { Button } from "../components/ui/button"
import { v4 as uuidv4 } from 'uuid';
import ContentTypeIcons from '../components/Layout/ContentTypeIcons';
import { ContentItemType } from '../types/ContentItem';

const componentTypes: { type: ContentItemType; label: string }[] = [
  { type: 'text', label: 'Text' },
  { type: 'textarea', label: 'Textarea' },
  { type: 'image', label: 'Image' },
  { type: 'video', label: 'Video' },
  { type: 'date', label: 'Date' },
  { type: 'url', label: 'URL' },
  { type: 'email', label: 'Email' },
];

const CreateProject: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [uploadError, setUploadError] = useState('');
  const {
    title,
    content,
    loading,
    error,
    handleTitleChange,
    handleContentChange,
    handleDeleteContent,
    handleDragEnd: handleContentDragEnd,
    handleSubmit,
    setContent
  } = useProjectForm();

  const handleContentChangeWrapper = (
    index: number,
    field: keyof ContentItem | 'heading' | 'provider',
    value: string
  ) => {
    if (field === 'heading' || field === 'provider') {
      // Handle these fields separately if needed
      console.log(`Changing ${field} for item at index ${index} to ${value}`);
      // You might want to update your content state here
    } else {
      handleContentChange(index, field, value);
    }
  };

  const handleImageUpload = async (index: number, file: File) => {
    try {
      setUploadError('');
      const imageUrl = await compressAndUploadImage(file);
      handleContentChange(index, 'value', imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
      if (error instanceof Error) {
        setUploadError(error.message);
      } else {
        setUploadError('An unknown error occurred');
      }
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    if (result.source.droppableId === 'sidebar' && result.destination.droppableId === 'content') {
      const newType = componentTypes[result.source.index].type;
      const newItem: ContentItem = {
        id: uuidv4(),
        type: newType,
        value: '',
        order: result.destination.index
      };

      setContent(prevContent => {
        const updatedContent = [...prevContent];
        updatedContent.splice(result.destination!.index, 0, newItem);
        return updatedContent.map((item, index) => ({ ...item, order: index }));
      });
    } else if (result.source.droppableId === 'content' && result.destination.droppableId === 'content') {
      handleContentDragEnd(result);
    }
  };

  if (!currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="min-h-screen bg-gray-100">
        <Header />
        <FloatingHeader title="Create New Project">
          <Button
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
            className='bg-purple-500 text-white'
          >
            {loading ? 'Creating...' : 'Save & Publish'}
          </Button>
        </FloatingHeader>
        <main className="container mx-auto px-4 py-8">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/4 mb-4 lg:mb-0 lg:mr-4 sticky top-20 left-0">
              <div className="bg-white shadow-md rounded px-1 py-4 lg:px-4 sticky top-20 left-0">
                <h2 className="text-lg font-semibold mb-4 lg:block hidden">Add Content</h2>
                <Droppable droppableId="sidebar" direction="horizontal">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} 
                         className="flex flex-wrap lg:flex-col justify-center">
                      <ContentTypeIcons />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
            <div className="lg:w-3/4">
              {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              {uploadError && (
                <Alert variant="destructive" className="mb-4">
                  <AlertDescription>{uploadError}</AlertDescription>
                </Alert>
              )}
              <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Project Title
                  </label>
                  <Input
                    id="title"
                    placeholder="Enter project title"
                    value={title}
                    onChange={handleTitleChange}
                  />
                </div>
                <Droppable droppableId="content">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className='droppable-area'>
                      <ContentList
                        content={content}
                        onContentChange={handleContentChangeWrapper}
                        onDeleteContent={handleDeleteContent}
                        onFileUpload={handleImageUpload}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </form>
            </div>
          </div>
        </main>
      </div>
    </DragDropContext>
  );
};

export default CreateProject;