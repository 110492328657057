import React from 'react';
import { VideoContentItem } from '../../types/ContentItem';

interface VideoContentProps {
  item: VideoContentItem;
}

export const VideoContent: React.FC<VideoContentProps> = ({ item }) => {
  const getEmbedUrl = (url: string, provider: 'youtube' | 'vimeo'): string => {
    if (provider === 'youtube') {
      const videoId = url.split('v=')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (provider === 'vimeo') {
      const videoId = url.split('/').pop();
      return `https://player.vimeo.com/video/${videoId}`;
    }
    return url;
  };

  return (
    <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src={getEmbedUrl(item.value, item.provider)}
        className="absolute top-0 left-0 w-full h-full"
        frameBorder="0"
        allowFullScreen
        title="Embedded video"
      />
    </div>
  );
};