import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Header } from '../components/Layout/Header';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Alert, AlertDescription } from "../components/ui/alert";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Label } from "../components/ui/label";
import { firestore, auth, storage } from '../services/firebase';
import { ref, getMetadata } from 'firebase/storage';
import { doc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { deleteUser, sendPasswordResetEmail, updateEmail } from 'firebase/auth';
import { ContentItem } from '../types/ContentItem';

const Settings: React.FC = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [projectCount, setProjectCount] = useState<number | null>(null);
  const [storageUsage, setStorageUsage] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const calculateStorageUsage = useCallback(async (content: ContentItem[]): Promise<number> => {
    let totalSize = 0;
    for (const item of content) {
      if (item.type === 'image' && item.value.includes('firebasestorage.googleapis.com')) {
        try {
          const fileRef = ref(storage, item.value);
          const metadata = await getMetadata(fileRef);
          totalSize += metadata.size / (1024 * 1024); // Convert bytes to MB
        } catch (error) {
          console.error('Error fetching file size:', error);
          // If we can't get the size, we'll use the 0.5 MB assumption
          totalSize += 0.5;
        }
      }
    }
    return totalSize;
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!currentUser) return;

    try {
      setIsLoading(true);
      const userRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setEmail(userData.email || '');

        // Query projects for the current user
        const projectsRef = collection(firestore, 'projects');
        const projectsQuery = query(projectsRef, where('userId', '==', currentUser.uid));
        const projectSnapshots = await getDocs(projectsQuery);
        
        const count = projectSnapshots.size;
        let usage = 0;

        // Calculate storage usage
        for (const doc of projectSnapshots.docs) {
          const projectData = doc.data();
          usage += await calculateStorageUsage(projectData.content as ContentItem[]);
        }

        // Update user document with new calculations
        await updateDoc(userRef, {
          projectCount: count,
          storageUsage: usage
        });

        setProjectCount(count);
        setStorageUsage(usage);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, calculateStorageUsage]);

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    } else {
      navigate('/login');
    }
  }, [currentUser, navigate, fetchUserData]);

  const handleEmailChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    setIsLoading(true);
    setError(null);
    setMessage(null);

    try {
      await updateEmail(currentUser, newEmail);
      await updateDoc(doc(firestore, 'users', currentUser.uid), { email: newEmail });
      setEmail(newEmail);
      setNewEmail('');
      setMessage('Email updated successfully.');
    } catch (error) {
      setError('Failed to update email. Please try again.');
      console.error('Error updating email:', error);
    }

    setIsLoading(false);
  };

  const handleResetPassword = async () => {
    if (!currentUser?.email) return;

    setIsLoading(true);
    setError(null);
    setMessage(null);

    try {
      await sendPasswordResetEmail(auth, currentUser.email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      console.error('Error sending password reset email:', error);
    }

    setIsLoading(false);
  };

  const handleDeleteAccount = async () => {
    if (!currentUser) return;

    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsLoading(true);
      setError(null);
      setMessage(null);

      try {
        // Delete user data from Firestore
        await deleteDoc(doc(firestore, 'users', currentUser.uid));
        // Delete user authentication
        await deleteUser(currentUser);
        await logout();
        navigate('/');
      } catch (error) {
        setError('Failed to delete account. Please try again.');
        console.error('Error deleting account:', error);
      }

      setIsLoading(false);
    }
  };

  const handleUpgradeAccount = () => {
    // Implement upgrade account logic here
    console.log('Upgrade account');
  };

  const handleInviteOthers = () => {
    // Implement invite others logic here
    console.log('Invite others');
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">Account Settings</h1>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {message && (
          <Alert variant="default" className="mb-4 bg-green-100 dark:bg-green-900 border-green-400 dark:border-green-700">
            <AlertDescription>{message}</AlertDescription>
          </Alert>
        )}
        <div className="space-y-6">
        <Card>
            <CardHeader>
              <CardTitle>Account Information</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div>
                  <Label>Email</Label>
                  <p className="text-gray-600 dark:text-gray-400">{email}</p>
                </div>
                <div>
                  <Label>Projects Created</Label>
                  <p className="text-gray-600 dark:text-gray-400">
                    {projectCount !== null ? projectCount : 'Loading...'}
                  </p>
                </div>
                <div>
                  <Label>Storage Usage</Label>
                  <p className="text-gray-600 dark:text-gray-400">
                    {storageUsage !== null ? `${storageUsage.toFixed(2)} MB` : 'Loading...'}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Change Email</CardTitle>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleEmailChange} className="space-y-4">
                <div>
                  <Label htmlFor="newEmail">New Email</Label>
                  <Input
                    id="newEmail"
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                  />
                </div>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? 'Updating...' : 'Update Email'}
                </Button>
              </form>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Security</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <Button onClick={handleResetPassword} disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Reset Password'}
              </Button>
              <Button variant="destructive" onClick={handleDeleteAccount} disabled={isLoading}>
                {isLoading ? 'Deleting...' : 'Delete Account'}
              </Button>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Account Upgrade</CardTitle>
            </CardHeader>
            <CardContent>
              <Button onClick={handleUpgradeAccount}>Upgrade Account</Button>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Upgrade your account to get more storage and advanced features.
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Invite Others</CardTitle>
            </CardHeader>
            <CardContent>
              <Button onClick={handleInviteOthers}>Invite Friends</Button>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Invite your friends to join and collaborate on projects.
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Notification Preferences</CardTitle>
            </CardHeader>
            <CardContent>
              {/* Add notification preferences toggles here */}
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Notification preferences coming soon.
              </p>
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default Settings;