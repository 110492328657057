import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Alert, AlertDescription } from "../components/ui/alert";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Header } from '../components/Layout/Header';
import { Checkbox } from "../components/ui/checkbox";
import { ContentItem, ImageContentItem, TextContentItem } from '../types/ContentItem';
import { createProject } from '../services/projectService';
import { getUserStorageInfo } from '../services/userService';
import he from 'he';

interface ScrapedItem {
  type: 'heading' | 'paragraph' | 'image';
  content: string;
  level?: number;
  width?: number;
  height?: number;
}

interface ScrapedData {
  title: string;
  content: ScrapedItem[];
}

const cleanText = (text: unknown): string => {
  if (typeof text !== 'string') return '';
  return he.decode(text)
    .replace(/\[email&#160;protected\]/g, '[email protected]')
    .replace(/&#x27;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
};

function isImageContentItem(item: ContentItem): item is ImageContentItem {
  return item.type === 'image' && 'estimatedSize' in item;
}

const WebScraper: React.FC = () => {
  const [url, setUrl] = useState('');
  const [scrapedData, setScrapedData] = useState<ScrapedData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedContent, setSelectedContent] = useState<ContentItem[]>([]);
  const [projectTitle, setProjectTitle] = useState('');
  const [userStorageLimit, setUserStorageLimit] = useState<number>(0);
  const [userStorageUsed, setUserStorageUsed] = useState<number>(0);
  const [estimatedImportSize, setEstimatedImportSize] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserStorageInfo = async () => {
      const { storageLimit, storageUsed } = await getUserStorageInfo();
      setUserStorageLimit(storageLimit);
      setUserStorageUsed(storageUsed);
    };

    fetchUserStorageInfo();
  }, []);

  const estimateImageSize = (width: number, height: number): number => {
    return (width * height * 0.5) / (1024 * 1024);
  };
  
  const handleCreateProject = async () => {
    try {
      setLoading(true);
      setError(null);

      const filteredContent = selectedContent.filter(item => item.selected !== false);
      
      const totalSelectedSize = filteredContent.reduce((sum, item) => {
        if (isImageContentItem(item) && !item.value.startsWith('http')) {
          // Only count size for images that are not already URLs (i.e., will be uploaded)
          return sum + (item.estimatedSize ?? 0);
        }
        return sum;
      }, 0);

      if (userStorageUsed + totalSelectedSize > userStorageLimit) {
        setError(`Importing this content would exceed your storage limit. Please deselect some images or upgrade your account.`);
        setLoading(false);
        return;
      }

      // Modify the content to include a flag for images that need to be uploaded
      const contentWithUploadFlags = filteredContent.map(item => {
        if (isImageContentItem(item)) {
          return {
            ...item,
            needsUpload: !item.value.startsWith('http')
          };
        }
        return item;
      });

      const projectRef = await createProject({
        title: projectTitle,
        content: contentWithUploadFlags
      });
      navigate(`/project/${projectRef.id}`);
    } catch (error) {
      console.error('Error creating project:', error);
      setError('Failed to create project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleScrape = async () => {
    try {
      setError(null);
      setLoading(true);
  
      let normalizedUrl = url.trim();
      if (!normalizedUrl.match(/^https?:\/\//i)) {
        normalizedUrl = 'https://' + normalizedUrl;
      }
  
      console.log('Sending request to:', normalizedUrl);
  
      const response = await fetch('https://scraper.brettdesigner.workers.dev/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: normalizedUrl })
      });
  
      console.log('Response status:', response.status);
  
      const data = await response.json();
  
      console.log('Received data:', JSON.stringify(data, null, 2));
  
      if (!response.ok) {
        throw new Error(`Scraping failed: ${data.error || response.statusText}`);
      }
  
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data structure received from the scraper');
      }
  
      const decodedData: ScrapedData = {
        title: data.title ? cleanText(data.title) : 'Untitled Project',
        content: Array.isArray(data.content) ? data.content.map((item: ScrapedItem) => ({
          ...item,
          content: item.content ? (item.type === 'image' ? item.content : cleanText(item.content)) : ''
        })) : []
      };
  
      console.log('Decoded data:', JSON.stringify(decodedData, null, 2));
  
      setScrapedData(decodedData);
      setProjectTitle(decodedData.title);

      let totalSize = 0;
      decodedData.content.forEach(item => {
        if (item.type === 'image' && item.width && item.height) {
          totalSize += estimateImageSize(item.width, item.height);
        }
      });
      setEstimatedImportSize(totalSize);
  
      const contentItems: ContentItem[] = decodedData.content
        .map((item: ScrapedItem, index: number): ContentItem | null => {
          switch (item.type) {
            case 'heading':
              return {
                id: `heading-${index}`,
                type: 'text',
                value: item.content || '',
                order: index,
                heading: item.level && item.level <= 2 ? 'large' : item.level === 3 ? 'medium' : 'small',
                selected: true
              } as TextContentItem;
            case 'paragraph':
              return {
                id: `paragraph-${index}`,
                type: 'textarea',
                value: item.content || '',
                order: index,
                selected: true
              };
            case 'image':
              return {
                id: `image-${index}`,
                type: 'image',
                value: item.content || '',
                order: index,
                selected: true,
                estimatedSize: item.width && item.height ? estimateImageSize(item.width, item.height) : 0,
              } as ImageContentItem;
            default:
              console.warn(`Unexpected content type: ${item.type}`);
              return null;
          }
        })
        .filter((item): item is ContentItem => item !== null);

      console.log('Content items:', JSON.stringify(contentItems, null, 2));

      setSelectedContent(contentItems);
    } catch (err) {
      console.error('Detailed error:', err);
      if (err instanceof Error) {
        setError(`Failed to scrape the website: ${err.message}`);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleContentToggle = (id: string) => {
    setSelectedContent(prev => 
      prev.map(item => 
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Project Import</h1>
        <p className="mb-4">Enter a URL to scrape the website. Please be sure this is content you own. We can't access pages behind password protected pages. Once you click preview import you can deselect any content you dont wish to import. Once you're ready click the "Create Project" button at the bottom.</p>
        <div className="flex space-x-2 mb-4">
          <Input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL to import (e.g., example.com)"
            className="flex-grow"
          />  
          <Button onClick={handleScrape} disabled={loading}>
            {loading ? 'Importing...' : 'Import Preview'}
          </Button>
        </div>
        <div>
          {estimatedImportSize > 0 && (
            <p>Estimated import size: {estimatedImportSize.toFixed(2)} MB</p>
          )}
          <p>Storage used: {userStorageUsed.toFixed(2)} MB / {userStorageLimit.toFixed(2)} MB</p>
          {/* ... (rest of the JSX) */}
        </div>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {scrapedData && (
          <Card>
            <CardHeader>
              <CardTitle>
                <label htmlFor="projectTitle" className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>Project Title</label>
                <Input
                  type="text"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                  placeholder="Enter project title"
                  className="w-full mt-2"
                />
              </CardTitle>
            </CardHeader>
            <CardContent>
            <h2 className='mb-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>Content Preview</h2>
              {selectedContent.map((item) => (
                
                <div 
                  key={item.id} 
                  className={`flex items-center space-x-2 mb-1 border border-gray-300 p-2 rounded-md ${item.selected ? 'bg-white' : 'bg-white opacity-50'} transition-opacity duration-300`}
                >
                  <Checkbox
                    id={item.id}
                    checked={item.selected}
                    onCheckedChange={() => handleContentToggle(item.id)}
                  />
                  <label htmlFor={item.id} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    {item.type === 'image' ? (
                      <img src={item.value} alt="Scraped content" className="max-h-32 w-auto object-contain" />
                    ) : (
                      item.value.substring(0, 100) + (item.value.length > 100 ? '...' : '')
                    )}
                  </label>
                </div>
              ))}
              <Button onClick={handleCreateProject} disabled={loading || selectedContent.length === 0}>
                Create Project
              </Button>
            </CardContent>
          </Card>
        )}
      </main>
    </div>
  );
};

export default WebScraper;