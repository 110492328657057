import imageCompression from 'browser-image-compression';
import { uploadImageToFirebase } from './firebaseStorageUtils';

export const compressAndUploadImage = async (file: File): Promise<string> => {
  try {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    const compressedFile = await imageCompression(file, options);
    const downloadURL = await uploadImageToFirebase(compressedFile);
    return downloadURL;
  } catch (error: unknown) {
    console.error('Error compressing and uploading image:', error);
    if (error instanceof Error) {
      if (error.message.includes('User must be authenticated')) {
        throw new Error('You must be logged in to upload images');
      }
    }
    throw new Error('Failed to compress and upload image. Please try again.');
  }
};