import React from 'react';
import './LoadScreen.css';

interface LoadingScreenProps {
  progress?: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ progress }) => {
  return (
    <div className="fixed inset-0 bg-gray-100 flex flex-col items-center justify-center">
      <div className="text-gray-600 text-4xl font-bold mb-4">
        Loading<span className="animated-ellipsis"></span>
      </div>
      {progress !== undefined && (
        <div className="w-64 bg-white rounded-full h-2">
          <div 
            className="bg-blue-600 h-2 rounded-full transition-all duration-300 ease-in-out" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;