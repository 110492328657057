import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Project } from '../../types/Project';

interface ProjectGridProps {
  projects: Project[];
}

const ProjectGrid: React.FC<ProjectGridProps> = ({ projects }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {projects.map((project) => (
        <Link 
          to={`/project/${project.id}`} 
          key={project.id}
          className="block hover:shadow-lg transition-shadow duration-300"
        >
          <Card className="h-full bg-white">
            <CardHeader>
              <CardTitle className='text-lg font-bold'>{project.title}</CardTitle>
              <p className="text-sm text-gray-500 mt-2">
                Last Edited: {project.updatedAt ? new Date(project.updatedAt).toLocaleString() : 'Never'}
              </p>
            </CardHeader>
            <CardContent>
              <span className="text-blue-600">View Project</span>
            </CardContent>
          </Card>
        </Link>
      ))}
    </div>
  );
};

export default ProjectGrid;