import React, { Suspense } from 'react';
import { Link, Navigate, Routes, Route } from 'react-router-dom';
import { useAdmin } from '../contexts/AdminContext';
import { Header } from '../components/Layout/Header';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import ManageUsers from './ManageUsers';
import ManageProjects from './ManageProjects';
import ManagePermissions from './ManagePermissions';

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const errorHandler = (error: ErrorEvent) => {
      console.error('Error caught by error boundary:', error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => window.removeEventListener('error', errorHandler);
  }, []);

  if (hasError) {
    return <Alert variant="destructive"><AlertDescription>Something went wrong.</AlertDescription></Alert>;
  }

  return <>{children}</>;
};

const Admin: React.FC = () => {
  const { isAdmin, isSuperAdmin, loading, error } = useAdmin();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!isAdmin && !isSuperAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
        <div className="flex mb-6">
          <Button asChild className="mr-4">
            <Link to="/admin/users">Manage Users</Link>
          </Button>
          <Button asChild className="mr-4">
            <Link to="/admin/projects">Manage Projects</Link>
          </Button>
          {isSuperAdmin && (
            <Button asChild>
              <Link to="/admin/permissions">Manage Permissions</Link>
            </Button>
          )}
        </div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="users" element={<ManageUsers />} />
              <Route path="projects" element={<ManageProjects />} />
              <Route path="permissions" element={<ManagePermissions />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Admin;