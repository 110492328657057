import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Header } from '../components/Layout/Header';
import ProjectGrid from '../components/Projects/ProjectGrid';
import { Project } from '../types/Project';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Checkbox } from "../components/ui/checkbox";
import Confetti from 'react-confetti';

type ChecklistItem = keyof typeof initialChecklist;

const initialChecklist = {
  createProject: false,
  importProject: false,
  installExtension: false,
  installFigmaPlugin: false,
  shareWithFriend: false,
  editProject: false,
  addNote: false,
  customizeProfile: false,
};

const Home: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [checklist, setChecklist] = useState(initialChecklist);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);

  const handleCheckChange = (key: ChecklistItem) => {
    setChecklist(prev => {
      const newChecklist = { ...prev, [key]: !prev[key] };
      if (newChecklist[key]) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 2000);
      }
      const allCompleted = Object.values(newChecklist).every(Boolean);
      if (allCompleted) {
        setShowCongrats(true);
      }
      return newChecklist;
    });
  };

  useEffect(() => {
    if (showCongrats) {
      const timer = setTimeout(() => setShowCongrats(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showCongrats]);

  const completedSteps = Object.values(checklist).filter(Boolean).length;
  const totalSteps = Object.keys(checklist).length;
  const progressPercentage = (completedSteps / totalSteps) * 100;

  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser) {
        const projectsRef = collection(firestore, 'projects');
        const q = query(
          projectsRef, 
          where('userId', '==', currentUser.uid),
          orderBy('updatedAt', 'desc') // Sort by updatedAt in descending order
        );
        const querySnapshot = await getDocs(q);
        const fetchedProjects: Project[] = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          updatedAt: doc.data().updatedAt?.toDate() // Convert Firestore Timestamp to Date
        } as Project));
        setProjects(fetchedProjects);
      }
      setLoading(false);
    };

    fetchProjects();
  }, [currentUser]);

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <Card className='bg-white'>
            <CardHeader>
              <CardTitle>Onboarding Progress</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full" 
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600 mb-4">
                {completedSteps} of {totalSteps} steps completed
              </p>
              <div className="space-y-2">
                {(Object.keys(checklist) as ChecklistItem[]).map((key) => (
                  <div key={key} className="flex items-center">
                    <Checkbox 
                      id={key}
                      checked={checklist[key]}
                      onCheckedChange={() => handleCheckChange(key)}
                    />
                    <label htmlFor={key} className="ml-2 capitalize">
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </label>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
        <h1 className="text-3xl font-bold mb-6">My Projects</h1>
        {loading ? (
          <p>Loading projects...</p>
        ) : projects.length > 0 ? (
          <ProjectGrid projects={projects} />
        ) : (
          <p>No projects found. Create your first project!</p>
        )}
      </main>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.5}
        />
      )}
      {showCongrats && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-2">Congratulations!</h2>
            <p>You've completed all onboarding steps!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;