import React, { useState, useEffect } from 'react';
import { firestore } from '../services/firebase';
import { collection, query, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useAdmin } from '../contexts/AdminContext';
import { Link } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";

interface Project {
  id: string;
  title: string;
  userId: string;
  createdAt: Date;
  userEmail?: string;
}

const ManageProjects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isAdmin, loading: adminLoading } = useAdmin();
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);

  useEffect(() => {
    const fetchProjects = async () => {
      if (adminLoading) return;
      if (!isAdmin) {
        setError('You do not have permission to view this page.');
        setLoading(false);
        return;
      }
    
      try {
        console.log('Fetching projects...');
        const projectsRef = collection(firestore, 'projects');
        const q = query(projectsRef);
        const querySnapshot = await getDocs(q);
        console.log('Query snapshot:', querySnapshot);
    
        const fetchedProjects: Project[] = await Promise.all(
          querySnapshot.docs.map(async (projectDoc) => {
            const projectData = projectDoc.data();
            let userEmail = 'Unknown';
    
            // Fetch user data to get email
            if (projectData.userId) {
              const userDocRef = doc(firestore, 'users', projectData.userId);
              const userDocSnap = await getDoc(userDocRef);
              if (userDocSnap.exists()) {
                userEmail = userDocSnap.data().email || 'Unknown';
              }
            }
    
            return {
              id: projectDoc.id,
              ...projectData,
              createdAt: projectData.createdAt?.toDate() || new Date(),
              userEmail: userEmail
            } as Project;
          })
        );
    
        console.log('Fetched projects:', fetchedProjects);
        setProjects(fetchedProjects);
        setError(null);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [isAdmin, adminLoading]);

  const handleDeleteClick = (project: Project) => {
    setProjectToDelete(project);
  };

  const handleConfirmDelete = async () => {
    if (projectToDelete) {
      try {
        await deleteDoc(doc(firestore, 'projects', projectToDelete.id));
        setProjects(projects.filter(project => project.id !== projectToDelete.id));
        setProjectToDelete(null);
      } catch (err) {
        console.error('Error deleting project:', err);
        setError('Failed to delete project. Please try again.');
      }
    }
  };

  const handleCancelDelete = () => {
    setProjectToDelete(null);
  };

  if (adminLoading) return <div>Checking permissions...</div>;
  if (!isAdmin) return <Alert variant="destructive"><AlertDescription>You do not have permission to view this page.</AlertDescription></Alert>;
  if (loading) return <div>Loading projects...</div>;
  if (error) return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Projects</h2>
      {projects.length === 0 ? (
        <p>No projects found.</p>
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>User Email</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {projects.map(project => (
              <TableRow key={project.id}>
                <TableCell>
                  <Link to={`/project/${project.id}`} className="text-blue-600 hover:underline">
                    {project.title}
                  </Link>
                </TableCell>
                <TableCell>{project.userEmail}</TableCell>
                <TableCell>{project.createdAt.toLocaleString()}</TableCell>
                <TableCell>
                  <Button variant="destructive" onClick={() => handleDeleteClick(project)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <AlertDialog open={projectToDelete !== null} onOpenChange={handleCancelDelete}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this project?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the project
              "{projectToDelete?.title}" and remove all of its data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelDelete}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmDelete}>Delete Project</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ManageProjects;