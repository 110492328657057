import React from 'react';
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface BaseContentProps {
  value: string;
  onChange: (value: string) => void;
}

interface TextContentProps extends BaseContentProps {
  heading: string;
  onHeadingChange: (value: string) => void;
}

export const TextContent: React.FC<TextContentProps> = ({ value, onChange, heading, onHeadingChange }) => (
  <div className="mb-2">
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full p-2 border rounded"
      placeholder="Enter text"
    />
    <select
      value={heading}
      onChange={(e) => onHeadingChange(e.target.value)}
      className="mt-1 p-2 border rounded"
    >
      <option value="none">Base</option>
      <option value="small">Small Heading</option>
      <option value="medium">Medium Heading</option>
      <option value="large">Large Heading</option>
    </select>
  </div>
);

export const TextareaContent: React.FC<BaseContentProps> = ({ value, onChange }) => (
  <textarea
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full p-2 border rounded"
    rows={3}
    placeholder="Enter long text"
  />
);

export const EmailContent: React.FC<BaseContentProps> = ({ value, onChange }) => (
  <input
    type="email"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full p-2 border rounded"
    placeholder="Enter email"
  />
);

export const DateContent: React.FC<BaseContentProps> = ({ value, onChange }) => (
  <input
    type="date"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full p-2 border rounded"
  />
);

export const UrlContent: React.FC<BaseContentProps> = ({ value, onChange }) => (
  <input
    type="url"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full p-2 border rounded"
    placeholder="Enter URL"
  />
);

interface ImageContentProps extends BaseContentProps {
  onFileUpload: (file: File) => void;
}

export const ImageContent: React.FC<ImageContentProps> = ({ value, onChange, onFileUpload }) => (
  <div>
    <input
      type="url"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full p-2 border rounded mb-2"
      placeholder="Enter image URL"
    />
    <input
      type="file"
      accept="image/*"
      onChange={(e) => e.target.files && onFileUpload(e.target.files[0])}
      className="w-full p-2 border rounded"
    />
    {value && (
      <img src={value} alt="Uploaded content" className="mt-2 max-w-full h-auto" />
    )}
  </div>
);

interface VideoContentProps extends BaseContentProps {
  provider: 'youtube' | 'vimeo';
  onProviderChange: (value: 'youtube' | 'vimeo') => void;
}

export const VideoContent: React.FC<VideoContentProps> = ({ value, onChange, provider, onProviderChange }) => (
  <div className="space-y-2">
    <Input
      type="url"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter video URL"
      className="w-full"
    />
    <Select
      value={provider}
      onValueChange={onProviderChange}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select video provider" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="youtube">YouTube</SelectItem>
        <SelectItem value="vimeo">Vimeo</SelectItem>
      </SelectContent>
    </Select>
  </div>
);