// In ContentTypeIcons.tsx
import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Type, AlignLeft, Image, Video, Calendar, Link2, Mail } from 'lucide-react';
import { ContentItemType } from '../../types/ContentItem';

export const componentTypes: { type: ContentItemType; label: string; icon: React.ComponentType<any> }[] = [
  { type: 'text', label: 'Text', icon: Type },
  { type: 'textarea', label: 'Textarea', icon: AlignLeft },
  { type: 'image', label: 'Image', icon: Image },
  { type: 'video', label: 'Video', icon: Video },
  { type: 'date', label: 'Date', icon: Calendar },
  { type: 'url', label: 'URL', icon: Link2 },
  { type: 'email', label: 'Email', icon: Mail },
];

const ContentTypeIcons: React.FC = () => {
  return (
    <>
      {componentTypes.map((componentType, index) => (
        <Draggable key={componentType.type} draggableId={componentType.type} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="bg-gray-100 p-2 rounded cursor-move flex items-center 
                         lg:flex-row lg:w-full mb-2 mr-1 md:mr-2"
            >
              <componentType.icon className="h-6 w-6" />
              <span className="ml-2 text-xs hidden lg:inline md:inline">{componentType.label}</span>
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default ContentTypeIcons;