// pages/Custom404.tsx

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Header } from '../components/Layout/Header';

const Custom404: React.FC = () => {
  const location = useLocation();
  const isPermissionError = location.state && location.state.permissionDenied;

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">
          {isPermissionError ? '403' : '404'}
        </h1>
        <p className="text-2xl text-gray-600 mb-8">
          {isPermissionError 
            ? "😞 Oops! You don't have permission to access this page."
            : "😞 Oops! We can't seem to find 🔎 the page you're looking for."}
        </p>
        <Button asChild className='bg-blue-500 text-white'>
          <Link to="/">Go back to homepage</Link>
        </Button>
      </main>
    </div>
  );
};

export default Custom404;