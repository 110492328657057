import React from 'react';
import { Header } from '../components/Layout/Header';

const TermsAndConditions: React.FC = () => {

return (
  <div className="min-h-screen bg-gray-100">
    <Header />
    <div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold mb-6">Folio-Linked Terms and Conditions</h1>
      <p className="mb-4">Last updated: September 3, 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Agreement to Terms</h2>
        <p>By accessing or using Folio-Linked, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you disagree with any part of the terms, you may not access the service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
        <p>Folio-Linked is a platform that allows users to create, manage, and share digital projects and content. Our service includes features for content creation, collaboration, and storage.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
        <p>To use certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. User Content</h2>
        <p>You retain all rights in, and are solely responsible for, the content you post to Folio-Linked. By posting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute that content in connection with the service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Acceptable Use</h2>
        <p>You agree not to use the Service to:</p>
        <ul className="list-disc pl-8">
          <li>Violate any laws or regulations</li>
          <li>Infringe on the rights of others</li>
          <li>Transmit any malicious code or interfere with the service</li>
          <li>Impersonate any person or entity</li>
          <li>Engage in any activity that interferes with or disrupts the Service</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property</h2>
        <p>The Service and its original content, features, and functionality are owned by Folio-Linked and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
        <p>In no event shall Folio-Linked, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Changes to Terms</h2>
        <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <a 
          className="text-blue-600 visited:text-purple-600 hover:underline" 
          href="mailto:support@folio-linked.com"
        >
          support@folio-linked.com
        </a>
      </section>
    </div>
  </div>
);
};

export default TermsAndConditions;