// src/services/firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDXomOvg-Lp-tq1IskN9c0pOK5WbmpDcE8",
  authDomain: "folio-linked.firebaseapp.com",
  projectId: "folio-linked",
  storageBucket: "folio-linked.appspot.com",
  messagingSenderId: "1051937169555",
  appId: "1:1051937169555:web:009d04b4bcee9a0dae6d66",
  measurementId: "G-PHFN7FQ1YH"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

export const setupNewUser = async (uid: string, email: string) => {
  console.log('Setting up new user:', uid, email);
  try {
    const userRef = doc(firestore, 'users', uid);
    const permissionsRef = doc(firestore, 'settings', 'permissions');

    // Get default permissions
    const permissionsSnap = await getDoc(permissionsRef);
    console.log('Permissions snapshot exists:', permissionsSnap.exists());
    const defaultPermissions = permissionsSnap.exists() 
      ? permissionsSnap.data() 
      : {
          basicUserProjectLimit: 2,
          basicUserStorageLimit: 20,
        };
    console.log('Default permissions:', defaultPermissions);

    // Set up user document
    const userData = {
      email,
      role: 'basic',
      projectCount: 0,
      storageUsage: 0,
      projectLimit: defaultPermissions.basicUserProjectLimit,
      storageLimit: defaultPermissions.basicUserStorageLimit,
    };
    console.log('Setting user data:', userData);
    await setDoc(userRef, userData);
    console.log('User setup complete');
  } catch (error) {
    console.error('Error setting up new user:', error);
    throw error; // Re-throw the error so it can be caught in the registration process
  }
};

export default app;