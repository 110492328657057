import React from 'react';
import { Header } from '../components/Layout/Header';

const PrivacyPolicy: React.FC = () => {

return (
  <div className="min-h-screen bg-gray-100">
    <Header />
    <div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">Last updated: September 3, 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>Welcome to Folio-Linked. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Data We Collect</h2>
        <p>We collect and process the following data:</p>
        <ul className="list-disc pl-8">
          <li>Personal identification information (Name, email address)</li>
          <li>User-generated content (Projects, notes, uploaded images)</li>
          <li>Usage data (How you interact with our service)</li>
          <li>Technical data (IP address, browser type and version, time zone setting and location, operating system and platform)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. How We Collect Your Data</h2>
        <p>We collect data through:</p>
        <ul className="list-disc pl-8">
          <li>Direct interactions (when you create an account or use our services)</li>
          <li>Automated technologies (cookies, server logs)</li>
          <li>Third parties (Google Authentication)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. How We Use Your Data</h2>
        <p>We use your data to:</p>
        <ul className="list-disc pl-8">
          <li>Provide and maintain our service</li>
          <li>Notify you about changes to our service</li>
          <li>Allow you to participate in interactive features of our service</li>
          <li>Provide customer support</li>
          <li>Monitor the usage of our service</li>
          <li>Detect, prevent and address technical issues</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Data Storage and Security</h2>
        <p>We use Firebase for data storage and authentication. Your data is stored securely on Firebase servers. We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction or damage.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Data Sharing and Transfers</h2>
        <p>We do not sell your personal data to third parties. We may share your data with:</p>
        <ul className="list-disc pl-8">
          <li>Service providers (like Firebase) who provide IT and system administration services</li>
          <li>Professional advisers including lawyers, bankers, auditors and insurers</li>
          <li>Government bodies that require us to report processing activities</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Your Data Protection Rights</h2>
        <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:</p>
        <ul className="list-disc pl-8">
          <li>Request access to your personal data</li>
          <li>Request correction of your personal data</li>
          <li>Request erasure of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Right to withdraw consent</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Cookies</h2>
        <p>We use cookies and similar tracking technologies to track the activity on our service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <a 
          className="text-blue-600 visited:text-purple-600 hover:underline" 
          href="mailto:support@folio-linked.com"
        >
          support@folio-linked.com
        </a>
      </section>
    </div>
  </div>
);
};

export default PrivacyPolicy;