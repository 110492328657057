import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

interface EditLog {
  timestamp: Date | { seconds: number } | { toDate: () => Date };
  editType: string;
}

interface CustomNote {
  id: string;
  content: string;
  timestamp: Date | { seconds: number } | { toDate: () => Date };
}

interface Project {
  editLogs?: EditLog[];
  customNotes?: CustomNote[];
}

interface ProjectActivityLogProps {
  project: Project;
  onAddNote: (note: string) => void;
  onRemoveNote: (noteId: string) => void;
}

const ProjectActivityLog: React.FC<ProjectActivityLogProps> = ({ project, onAddNote, onRemoveNote }) => {
  const [newNote, setNewNote] = useState('');

  const handleAddNote = () => {
    if (newNote.trim()) {
      onAddNote(newNote);
      setNewNote('');
    }
  };

  const formatDate = (timestamp: Date | { seconds: number } | { toDate: () => Date }): string => {
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    } else if (timestamp && typeof (timestamp as any).toDate === 'function') {
      return (timestamp as { toDate: () => Date }).toDate().toLocaleString();
    } else if (timestamp && (timestamp as { seconds: number }).seconds) {
      return new Date((timestamp as { seconds: number }).seconds * 1000).toLocaleString();
    }
    return 'Invalid date';
  };

  return (
    <div className="rounded">
      <h2 className="text-xl font-bold mb-4">Project Activity</h2>
      
      {/* Edit Logs */}
      <div className="mb-6">
        {project.editLogs && project.editLogs.length > 0 && (
          <ul className="space-y-2 border-b border-gray-300 pb-6">
            {project.editLogs.map((log, index) => (
              <li key={index} className="mb-1">
                <div>
                <p className="text-sm text-gray-600">{formatDate(log.timestamp)}</p>
                <p>{log.editType}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Custom Notes */}
      <div>
        {project.customNotes && project.customNotes.length > 0 && (
          <ul className="space-y-2">
            {project.customNotes.map((note) => (
              <li key={note.id} className="flex items-start justify-between bg-gray-100 p-2 px-0 rounded">
                <div>
                  <p className="text-sm text-gray-600">{formatDate(note.timestamp)}</p>
                  <p>{note.content}</p>
                </div>
                <button
                  onClick={() => onRemoveNote(note.id)}
                  className="text-red-500 hover:text-red-700"
                  aria-label="Remove note"
                >
                  <FiX />
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className="mt-4">
          <textarea
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder="Add a new note...like what site you added this content to, or if you made any changes"
            className="w-full p-2 border rounded"
            rows={3}
          />
          <button
            onClick={handleAddNote}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Note
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectActivityLog;