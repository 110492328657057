import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import {
  TextContent,
  TextareaContent,
  EmailContent,
  DateContent,
  UrlContent,
  ImageContent,
  VideoContent
} from './ContentTypeComponents';
import { ContentItem, TextContentItem, VideoContentItem } from '../../types/ContentItem';

interface ContentListProps {
  content: ContentItem[];
  onContentChange: (index: number, field: keyof ContentItem, value: string) => void;
  onDeleteContent: (index: number) => void;
  onFileUpload: (index: number, file: File) => Promise<void>;
  renderAdditionalControls?: (index: number) => React.ReactNode;
}

const ContentList: React.FC<ContentListProps> = ({
  content,
  onContentChange,
  onDeleteContent,
  onFileUpload,
  renderAdditionalControls
}) => {
  const renderContentInput = (item: ContentItem, index: number) => {
    const props = {
      value: item.value,
      onChange: (value: string) => onContentChange(index, 'value', value),
    };

    switch (item.type) {
      case 'text':
        return (
          <TextContent
            {...props}
            heading={(item as TextContentItem).heading}
            onHeadingChange={(value: string) => onContentChange(index, 'heading' as keyof ContentItem, value)}
          />
        );
      case 'textarea':
        return <TextareaContent {...props} />;
      case 'email':
        return <EmailContent {...props} />;
      case 'date':
        return <DateContent {...props} />;
      case 'url':
        return <UrlContent {...props} />;
      case 'image':
        return <ImageContent {...props} onFileUpload={(file: File) => onFileUpload(index, file)} />;
      case 'video':
        return (
          <VideoContent
            {...props}
            provider={(item as VideoContentItem).provider}
            onProviderChange={(value: 'youtube' | 'vimeo') => onContentChange(index, 'provider' as keyof ContentItem, value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Droppable droppableId="content">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {content.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <Card
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="mb-4"
                >
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                    </CardTitle>
                    <div className="flex items-center">
                      {renderAdditionalControls && renderAdditionalControls(index)}
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => onDeleteContent(index)}
                        className="ml-2"
                      >
                        Delete
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent>
                    {renderContentInput(item, index)}
                  </CardContent>
                </Card>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ContentList;