// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { AdminProvider } from './contexts/AdminContext';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import CreateProject from './pages/CreateProject';
import ViewProject from './pages/ViewProject';
import WebScraper from './pages/WebScraper';
import Settings from './pages/Settings';
import Custom404 from './pages/Custom404';
import Admin from './pages/Admin';
import ManageUsers from './pages/ManageUsers';
import ManageProjects from './pages/ManageProjects';
import ManagePermissions from './pages/ManagePermissions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsConditions';
import LoadingScreen from './components/Common/LoadingScreen';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate app initialization
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust this time as needed

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <AuthProvider>
        <AdminProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/create-project" element={<CreateProject />} />
            <Route path="/project/:id" element={<ViewProject />} />
            <Route path="/webscraper" element={<WebScraper />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/admin/*" element={<Admin />}>
              <Route path="users" element={<ManageUsers />} />
              <Route path="projects" element={<ManageProjects />} />
              <Route path="permissions" element={<ManagePermissions />} />
            </Route>
            <Route path="*" element={<Custom404 />} />
          </Routes>
        </AdminProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;