import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getProject, 
  deleteProject, 
  addCustomNote, 
  removeCustomNote, 
  updateProject, 
  getProjectVersions, 
  revertToVersion
} from '../services/projectService';
import { Project, ProjectVersion } from '../types/Project';
import { Header } from '../components/Layout/Header';
import {
  TextContent,
  TextareaContent,
  EmailContent,
  DateContent,
  UrlContent,
  ImageContent
} from '../components/Layout/ViewContentTypeComponents';
import { ContentItem, TextContentItem, VideoContentItem } from '../types/ContentItem';
import ContentList from '../components/Layout/ContentList';
import { VideoContent } from '../components/Layout/VideoContent';
import { compressAndUploadImage } from '../utils/imageUtils';
import CopyButton from '../components/Common/CopyButton';
import FloatingHeader from '../components/Layout/FloatingHeader';
import ProjectActivityLog from '../components/Projects/ProjectActivityLog';
import VersionView from '../components/Projects/VersionView';
import { Button } from "../components/ui/button"
import { Alert, AlertDescription } from "../components/ui/alert"
import { Input } from "../components/ui/input"
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import ContentTypeIcons from '../components/Layout/ContentTypeIcons';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { ContentItemType } from '../types/ContentItem';
import LoadingScreen from '../components/Common/LoadingScreen';

const componentTypes: { type: ContentItemType; label: string }[] = [
  { type: 'text', label: 'Text' },
  { type: 'textarea', label: 'Textarea' },
  { type: 'image', label: 'Image' },
  { type: 'video', label: 'Video' },
  { type: 'date', label: 'Date' },
  { type: 'url', label: 'URL' },
  { type: 'email', label: 'Email' },
];

const ViewProject: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [error, setError] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [editedContent, setEditedContent] = useState<ContentItem[]>([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [versions, setVersions] = useState<ProjectVersion[]>([]);
  const [viewingVersion, setViewingVersion] = useState<ProjectVersion | null>(null);
  const [aiSuggestions, setAiSuggestions] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProjectAndVersions = async () => {
      try {
        setIsLoading(true);
        if (id) {
          const fetchedProject = await getProject(id) as Project;
          setProject(fetchedProject);
          setEditedTitle(fetchedProject.title);
          setEditedContent(fetchedProject.content);
    
          try {
            const fetchedVersions = await getProjectVersions(id);
            console.log('Fetched versions:', fetchedVersions);
            setVersions(fetchedVersions);
          } catch (versionError) {
            console.error('Error fetching versions:', versionError);
            setVersions([]);
          }
        }
        setError('');
      } catch (err) {
        const error = err as Error;
        setError('Error fetching project: ' + error.message);
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProjectAndVersions();
  }, [id]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedTitle(project?.title || '');
      setEditedContent(project?.content || []);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedTitle(project?.title || '');
    setEditedContent(project?.content || []);
  };

  const handleSave = async () => {
    try {
      if (id && project) {
        await updateProject(id, { title: editedTitle, content: editedContent });
        const updatedProject = await getProject(id) as Project;
        setProject(updatedProject);
        setIsEditing(false);
  
        try {
          const updatedVersions = await getProjectVersions(id);
          setVersions(updatedVersions);
        } catch (versionError) {
          console.error('Error fetching updated versions:', versionError);
        }
      }
    } catch (error) {
      console.error('Error updating project:', error);
      setError('Failed to update project. Please try again.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this project? This action cannot be undone.')) {
      try {
        if (id) {
          await deleteProject(id);
          navigate('/');
        }
      } catch (error) {
        const err = error as Error;
        setError('Failed to delete project: ' + err.message);
      }
    }
  };

  const handleAddNote = async (note: string) => {
    try {
      if (id) {
        const addedNote = await addCustomNote(id, note);
        setProject(prevProject => prevProject ? {
          ...prevProject,
          customNotes: [...(prevProject.customNotes || []), addedNote]
        } : null);
      }
    } catch (error) {
      const err = error as Error;
      setError('Failed to add note: ' + err.message);
    }
  };

  const handleRemoveNote = async (noteId: string) => {
    try {
      if (id) {
        await removeCustomNote(id, noteId);
        setProject(prevProject => prevProject ? {
          ...prevProject,
          customNotes: prevProject.customNotes?.filter(note => note.id !== noteId) || []
        } : null);
      }
    } catch (error) {
      const err = error as Error;
      setError('Failed to remove note: ' + err.message);
    }
  };

  const handleContentChange = (
    index: number,
    field: keyof ContentItem,
    value: string
  ) => {
    const updatedContent = [...editedContent];
    (updatedContent[index] as any)[field] = value;
    setEditedContent(updatedContent);
  };

  const handleCopyAllContent = () => {
    if (!project) return;

    const allContent = project.content.map(item => {
      switch (item.type) {
        case 'text':
          return `${(item as TextContentItem).heading !== 'none' ? `${(item as TextContentItem).heading.toUpperCase()}: ` : ''}${item.value}`;
        case 'image':
          return `[Image: ${item.value}]`;
        default:
          return item.value;
      }
    }).join('\n\n');

    navigator.clipboard.writeText(allContent).then(() => {
      alert('All content copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy content: ', err);
      setError('Failed to copy content. Please try again.');
    });
  };

  const handleDeleteContent = (index: number) => {
    const updatedContent = editedContent.filter((_, i) => i !== index);
    setEditedContent(updatedContent);
  };

  const handleImageUpload = async (index: number, file: File) => {
    try {
      const imageUrl = await compressAndUploadImage(file);
      handleContentChange(index, 'value', imageUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image. Please try again.');
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    if (result.source.droppableId === 'sidebar' && result.destination.droppableId === 'content') {
      const newType = componentTypes[result.source.index].type;
      const newItem: ContentItem = {
        id: Date.now().toString(),
        type: newType,
        value: '',
        order: result.destination.index
      };

      setEditedContent(prevContent => {
        const updatedContent = [...prevContent];
        updatedContent.splice(result.destination!.index, 0, newItem);
        return updatedContent.map((item, index) => ({ ...item, order: index }));
      });
    } else if (result.source.droppableId === 'content' && result.destination.droppableId === 'content') {
      const items = Array.from(editedContent);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const reorderedItems = items.map((item, index) => ({
        ...item,
        order: index
      }));

      setEditedContent(reorderedItems);
    }
  };

  const handleViewVersion = (version: ProjectVersion) => {
    setViewingVersion(version);
  };

  const handleCloseVersionView = () => {
    setViewingVersion(null);
  };

  const handleRevertToVersion = async () => {
    if (!id || !viewingVersion) return;
  
    console.log(`Attempting to revert to version: ${viewingVersion.id} for project: ${id}`);
  
    try {
      const updatedProject = await revertToVersion(id, viewingVersion.id);
      setProject(updatedProject);
      setEditedTitle(updatedProject.title);
      setEditedContent(updatedProject.content);
      setViewingVersion(null);
      alert('Project reverted successfully');
    } catch (error) {
      console.error('Error reverting to version:', error);
      if (error instanceof Error) {
        setError(`Failed to revert to selected version: ${error.message}`);
      } else {
        setError('Failed to revert to selected version. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!project) return <div>Project not found</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <FloatingHeader 
        title={
          <div className="flex items-center">
            <span className="mr-2">{project?.title}</span>
            {!isEditing && <CopyButton text={project?.title || ''} />}
          </div>
        }
      >
        {!isEditing ? (
          <>
            <Button
              onClick={handleEditToggle}
              variant="default"
              className="mr-2"
            >
              Edit Project
            </Button>
            <Button
              onClick={handleCopyAllContent}
              variant="secondary"
              className="mr-2"
            >
              Copy All Content
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Versions ({versions.length})</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='bg-white'>
                {versions.length > 0 ? (
                  versions.map((version, index) => (
                    <DropdownMenuItem className='hover:bg-gray-100'
                      key={version.id}
                      onClick={() => handleViewVersion(version)}
                    >
                      View Version {versions.length - index} 
                      ({new Date(version.createdAt).toLocaleString()})
                    </DropdownMenuItem>
                  ))
                ) : (
                  <DropdownMenuItem disabled>No versions available</DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ) : (
          <>
            <Button
              onClick={handleCancel}
              variant="secondary"
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="default"
              className="mr-2"
            >
              Save Changes
            </Button>
          </>
        )}
        <Button
          onClick={handleDelete}
          variant="destructive"
        >
          Delete Project
        </Button>
      </FloatingHeader>
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <main className="container mx-auto px-4 py-8">
        {isEditing ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/4 mb-4 lg:mb-0 lg:mr-4 sticky top-20">
                <div className="bg-white shadow-md rounded px-1 py-4 lg:px-4 sticky top-20">
                  <h2 className="text-lg font-semibold mb-4 lg:block hidden">Add Content</h2>
                  <Droppable droppableId="sidebar" direction="horizontal">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} 
                           className="flex flex-wrap lg:flex-col justify-center">
                        <ContentTypeIcons />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className="lg:w-3/4">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                      Project Title
                    </label>
                    <Input
                      id="title"
                      type="text"
                      placeholder="Enter project title"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                    />
                  </div>
                  
                  <ContentList
                    content={editedContent}
                    onContentChange={handleContentChange}
                    onDeleteContent={handleDeleteContent}
                    onFileUpload={handleImageUpload}
                  />
                  {aiSuggestions && (
                    <div className="mt-4 p-4 bg-blue-50 rounded-md">
                      <h3 className="text-lg font-semibold mb-2">AI Suggestions:</h3>
                      <p>{aiSuggestions}</p>
                      <Button
                        onClick={() => setAiSuggestions(null)}
                        variant="outline"
                        size="sm"
                        className="mt-2"
                      >
                        Close Suggestions
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DragDropContext>
        ) : (
          <div className="flex flex-col md:flex-row gap-8">
            <div className="flex-grow">
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                {project.content.map((item, index) => (
                  <div key={index} className="mb-4 flex space-between">
                    {(() => {
                      switch (item.type) {
                        case 'text':
                          return <TextContent item={item as TextContentItem} />;
                        case 'textarea':
                          return <TextareaContent item={item} />;
                        case 'email':
                          return <EmailContent item={item} />;
                        case 'date':
                          return <DateContent item={item} />;
                        case 'url':
                          return <UrlContent item={item} />;
                        case 'image':
                          return <ImageContent item={item} />;
                        case 'video':
                          return <VideoContent item={item as VideoContentItem} />;
                        default:
                          return <div>Unsupported content type: {(item as ContentItem).type}</div>;
                      }
                    })()}
                    <CopyButton text={item.value} />
                  </div>
                ))}
              </div>
            </div>
            <div className="md:w-1/3 min-w-80">
              <ProjectActivityLog
                project={project}
                onAddNote={handleAddNote}
                onRemoveNote={handleRemoveNote}
              />
            </div>
            {viewingVersion && (
              <VersionView
                version={viewingVersion}
                onRevert={handleRevertToVersion}
                onClose={handleCloseVersionView}
              />
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default ViewProject;