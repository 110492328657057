import { firestore } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export const getUserStorageInfo = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User must be authenticated to get storage info');
  }

  const userRef = doc(firestore, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error('User document not found');
  }

  const userData = userDoc.data();
  return {
    storageLimit: userData.storageLimit || 20, // Default to 20MB for free users
    storageUsed: userData.storageUsage || 0
  };
};