import React from 'react';
import { ProjectVersion, ContentItem } from '../../types/Project';
import {
  TextContent,
  TextareaContent,
  EmailContent,
  DateContent,
  UrlContent,
  ImageContent
} from '../Layout/ViewContentTypeComponents';
import { Button } from "../ui/button";

interface VersionViewProps {
  version: ProjectVersion;
  onRevert: () => void;
  onClose: () => void;
}

const VersionView: React.FC<VersionViewProps> = ({ version, onRevert, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Version from {new Date(version.createdAt).toLocaleString()}</h2>
        <div className="space-y-4">
          {version.content.map((item: ContentItem, index: number) => (
            <div key={index}>
              {(() => {
                switch (item.type) {
                  case 'text':
                    return <TextContent item={item as any} />;
                  case 'textarea':
                    return <TextareaContent item={item} />;
                  case 'email':
                    return <EmailContent item={item} />;
                  case 'date':
                    return <DateContent item={item} />;
                  case 'url':
                    return <UrlContent item={item} />;
                  case 'image':
                    return <ImageContent item={item} />;
                  default:
                    return <div>Unsupported content type: {(item as ContentItem).type}</div>;
                }
              })()}
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <Button onClick={onClose} variant="secondary">Close</Button>
          <Button onClick={onRevert} variant="default">Revert to this Version</Button>
        </div>
      </div>
    </div>
  );
};

export default VersionView;