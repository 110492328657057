import React, { ReactNode } from 'react';
import { Card, CardContent } from "../../components/ui/card";

interface FloatingHeaderProps {
  title: ReactNode;
  children: ReactNode;
}

const FloatingHeader: React.FC<FloatingHeaderProps> = ({ title, children }) => {
  return (
    <Card className="sticky top-0 z-10 bg-white rounded-none">
      <CardContent className="p-4">
        <div className="justify-between items-center flex gap-y-2">
          <h1 className="text-2xl font-bold w-full">{title}</h1>
          <div className="flex space-x-2 items-right">
            {children}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FloatingHeader;