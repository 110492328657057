import React from 'react';

interface BaseContentItem {
  value: string;
}

interface TextContentItem extends BaseContentItem {
  heading: 'none' | 'large' | 'medium' | 'small';
}

interface ImageContentItem extends BaseContentItem {
  alt?: string;
}

export const TextContent: React.FC<{ item: TextContentItem }> = ({ item }) => (
  <div className="relative">
    {item.heading !== 'none' ? (
      <h2 className={`font-bold ${
        item.heading === 'large' ? 'text-2xl' :
        item.heading === 'medium' ? 'text-xl' :
        'text-lg'
      } mb-2`}>
        {item.value}
      </h2>
    ) : (
      <p className="text-gray-700 font-normal">{item.value}</p>
    )}
  </div>
);

export const TextareaContent: React.FC<{ item: BaseContentItem }> = ({ item }) => (
  <div className="relative">
    <p className="text-gray-700">{item.value}</p>
  </div>
);

export const EmailContent: React.FC<{ item: BaseContentItem }> = ({ item }) => (
  <div className="relative">
    <a href={`mailto:${item.value}`} className="text-blue-500 hover:underline">{item.value}</a>
  </div>
);

export const DateContent: React.FC<{ item: BaseContentItem }> = ({ item }) => (
  <div className="text-gray-700">
    {new Date(item.value).toLocaleDateString()}
  </div>
);

export const UrlContent: React.FC<{ item: BaseContentItem }> = ({ item }) => (
  <div className="relative">
    <a href={item.value} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{item.value}</a>
  </div>
);

export const ImageContent: React.FC<{ item: ImageContentItem }> = ({ item }) => (
  <div className="relative">
    <img src={item.value} alt={item.alt || "Project content"} className="max-w-full h-auto" />
  </div>
);