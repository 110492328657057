import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ContentItem, ContentItemType } from '../types/ContentItem';
import { DropResult } from '@hello-pangea/dnd';
import { createProject, updateProject } from '../services/projectService';

interface Project {
  id?: string;
  title: string;
  content: ContentItem[];
}

const useProjectForm = (initialProject: Project | null = null) => {
  const [title, setTitle] = useState(initialProject?.title || '');
  const [content, setContent] = useState<ContentItem[]>(initialProject?.content || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleAddContent = (type: ContentItemType) => {
    const newContent: ContentItem = {
      id: uuidv4(),
      type,
      value: '',
      order: content.length
    };

    setContent(prevContent => {
      const updatedContent = [...prevContent, newContent];
      return updatedContent.map((item, i) => ({
        ...item,
        order: i
      }));
    });
  };

  const handleContentChange = (index: number, field: keyof ContentItem, value: string) => {
    setContent(prevContent => {
      const updatedContent = [...prevContent];
      updatedContent[index] = { ...updatedContent[index], [field]: value };
      return updatedContent;
    });
  };

  const handleDeleteContent = (index: number) => {
    setContent(prevContent => 
      prevContent
        .filter((_, i) => i !== index)
        .map((item, i) => ({ ...item, order: i }))
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    setContent(prevContent => {
      const items = Array.from(prevContent);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination!.index, 0, reorderedItem);

      return items.map((item, index) => ({ ...item, order: index }));
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim()) {
      setError('Please enter a project title');
      return;
    }
    setLoading(true);
    setError('');
    try {
      if (initialProject) {
        await updateProject(initialProject.id!, { title, content });
        navigate(`/project/${initialProject.id}`);
      } else {
        const projectRef = await createProject({ title, content });
        navigate(`/project/${projectRef.id}`);
      }
    } catch (error) {
      console.error('Error saving project:', error);
      setError('Failed to save project. Please try again.');
    }
    setLoading(false);
  };

  return {
    title,
    content,
    loading,
    error,
    setContent,
    handleTitleChange,
    handleAddContent,
    handleContentChange,
    handleDeleteContent,
    handleDragEnd,
    handleSubmit
  };
};

export default useProjectForm;