import { firestore } from '../services/firebase';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  getDoc, 
  deleteDoc, 
  query,
  where,
  orderBy,
  getDocs,
  arrayUnion,
  runTransaction,
  increment,
  Transaction
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { Project, ProjectVersion, ProjectData } from '../types/Project';
import { v4 as uuidv4 } from 'uuid';
import { ContentItem } from '../types/ContentItem';

const storage = getStorage();

const isFirebaseStorageUrl = (url: string): boolean => {
  return url.includes('firebasestorage.googleapis.com');
};

const calculateStorageUsage = (content: ContentItem[]): number => {
  return content.reduce((total, item) => {
    if (item.type === 'image' && isFirebaseStorageUrl(item.value)) {
      // Assuming an average size of 500KB per image. Adjust as needed.
      return total + 0.5;
    }
    return total;
  }, 0);
};

export const createProject = async (projectData: ProjectData): Promise<{ id: string }> => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be authenticated to create a project');
    }

    const userRef = doc(firestore, 'users', user.uid);

    const projectRef = await runTransaction(firestore, async (transaction: Transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error("User document does not exist!");
      }

      const userData = userDoc.data();
      const newStorageUsage = (userData.storageUsage || 0) + calculateStorageUsage(projectData.content as ContentItem[]);

      transaction.update(userRef, { 
        projectCount: increment(1),
        storageUsage: increment(newStorageUsage)
      });

      const newProjectRef = doc(collection(firestore, 'projects'));
      transaction.set(newProjectRef, {
        ...projectData,
        userId: user.uid,
        createdAt: new Date(),
      });

      return newProjectRef;
    });

    return { id: projectRef.id };
  } catch (error) {
    console.error('Error creating project:', error);
    throw new Error('Failed to create project. Please try again.');
  }
};

export const updateProject = async (projectId: string, projectData: Partial<Project>) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be authenticated to update a project');
    }

    const projectRef = doc(firestore, 'projects', projectId);
    const projectSnap = await getDoc(projectRef);
    
    if (!projectSnap.exists()) {
      throw new Error('Project not found');
    }

    const currentProject = projectSnap.data() as Project;

    // Create a new version
    const newVersion: ProjectVersion = {
      id: uuidv4(),
      projectId: projectId,
      content: currentProject.content,
      createdAt: new Date(),
      createdBy: user.uid,
    };

    // Add the new version to the versions collection
    await addDoc(collection(firestore, 'versions'), newVersion);

    // Update the project with new data
    await updateDoc(projectRef, {
      ...projectData,
      updatedAt: new Date(),
    });

    // Add an edit log
    await addEditLog(projectId, 'New version created');
  } catch (error) {
    console.error('Error updating project:', error);
    if (error instanceof FirebaseError) {
      if (error.code === 'permission-denied') {
        throw new Error('You do not have permission to update this project.');
      }
    }
    throw new Error('Failed to update project. Please try again.');
  }
};

export const getProjectVersions = async (projectId: string): Promise<ProjectVersion[]> => {
  try {
    console.log('Fetching versions for project:', projectId);
    const versionsRef = collection(firestore, 'versions');
    const q = query(
      versionsRef, 
      where('projectId', '==', projectId),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    console.log('Query snapshot size:', querySnapshot.size);
    const versions = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate()
    } as ProjectVersion));
    console.log('Mapped versions:', versions);
    return versions;
  } catch (error) {
    console.error('Error fetching project versions:', error);
    if (error instanceof FirebaseError && error.code === 'failed-precondition') {
      console.log('Index is being created. This may take a few minutes.');
      return []; // Return an empty array instead of throwing an error
    }
    throw new Error('Failed to fetch project versions. Please try again.');
  }
};

export const revertToVersion = async (projectId: string, versionId: string): Promise<Project> => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be authenticated to revert a project');
    }

    // Fetch the version document
    const versionsRef = collection(firestore, 'versions');
    const q = query(versionsRef, where('id', '==', versionId), where('projectId', '==', projectId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error(`Version document not found. Version ID: ${versionId}, Project ID: ${projectId}`);
      throw new Error(`Version not found. ID: ${versionId}`);
    }

    const versionDoc = querySnapshot.docs[0];
    const version = versionDoc.data() as ProjectVersion;

    // Fetch the project document
    const projectRef = doc(firestore, 'projects', projectId);
    const projectSnap = await getDoc(projectRef);

    if (!projectSnap.exists()) {
      console.error(`Project document not found. Project ID: ${projectId}`);
      throw new Error(`Project not found. ID: ${projectId}`);
    }

    // Ensure the content matches the ContentItem structure
    const updatedContent: ContentItem[] = version.content.map((item: any, index: number) => ({
      id: item.id || `${Date.now()}-${index}`,
      type: item.type,
      value: item.value,
      order: item.order || index,
      ...(item.type === 'text' && { heading: item.heading || 'none' }),
      ...(item.type === 'video' && { provider: item.provider || 'youtube' })
    }));

    // Update the project with the version's content
    await updateDoc(projectRef, {
      content: updatedContent,
      updatedAt: new Date(),
    });

    // Add an edit log for the revert
    await addEditLog(projectId, 'version_revert');

    // Return the updated project data
    const updatedProjectSnap = await getDoc(projectRef);
    return { id: projectId, ...(updatedProjectSnap.data() as Project) };
  } catch (error) {
    console.error('Error reverting project:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to revert project: ${error.message}`);
    } else {
      throw new Error('Failed to revert project. Please try again.');
    }
  }
};

// New function to delete old versions
export const deleteOldVersions = async (projectId: string, keepCount: number) => {
  try {
    const versionsRef = collection(firestore, 'versions');
    const q = query(
      versionsRef, 
      where('projectId', '==', projectId),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    const versions = querySnapshot.docs;

    if (versions.length <= keepCount) {
      return; // No need to delete if we have fewer versions than the keep count
    }

    const versionsToDelete = versions.slice(keepCount);
    const deletePromises = versionsToDelete.map(v => deleteDoc(doc(firestore, 'versions', v.id)));
    await Promise.all(deletePromises);
  } catch (error) {
    console.error('Error deleting old versions:', error);
    throw new Error('Failed to delete old versions. Please try again.');
  }
};

export const getProject = async (projectId: string): Promise<ProjectData & { id: string }> => {
  try {
    const projectRef = doc(firestore, 'projects', projectId);
    const projectSnap = await getDoc(projectRef);
    if (projectSnap.exists()) {
      return { id: projectSnap.id, ...(projectSnap.data() as ProjectData) };
    } else {
      throw new Error('Project not found');
    }
  } catch (error) {
    console.error('Error fetching project:', error);
    throw new Error('Failed to fetch project. Please try again.');
  }
};

export const deleteProject = async (projectId: string) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be authenticated to delete a project');
    }

    const projectRef = doc(firestore, 'projects', projectId);
    const userRef = doc(firestore, 'users', user.uid);

    await runTransaction(firestore, async (transaction: Transaction) => {
      const projectSnap = await transaction.get(projectRef);

      if (!projectSnap.exists()) {
        throw new Error('Project not found');
      }

      const projectData = projectSnap.data() as ProjectData;
      const storageUsage = calculateStorageUsage(projectData.content as ContentItem[]);

      const deletePromises = projectData.content
        .filter(item => item.type === 'image' && item.value && isFirebaseStorageUrl(item.value))
        .map(item => {
          const imageRef = ref(storage, item.value);
          return deleteObject(imageRef).catch(error => {
            console.error('Error deleting image:', error);
          });
        });

      await Promise.all(deletePromises);

      transaction.delete(projectRef);

      transaction.update(userRef, {
        projectCount: increment(-1),
        storageUsage: increment(-storageUsage)
      });
    });
  } catch (error) {
    console.error('Error deleting project:', error);
    throw new Error('Failed to delete project. Please try again.');
  }
};

export const addEditLog = async (projectId: string, editType: string) => {
  try {
    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, {
      editLogs: arrayUnion({
        timestamp: new Date(),
        editType: editType
      })
    });
  } catch (error) {
    console.error('Error adding edit log:', error);
    throw new Error('Failed to add edit log. Please try again.');
  }
};

export const addCustomNote = async (projectId: string, note: string) => {
  try {
    const projectRef = doc(firestore, 'projects', projectId);
    const newNote = {
      id: Date.now().toString(),
      content: note,
      timestamp: new Date()
    };
    await updateDoc(projectRef, {
      customNotes: arrayUnion(newNote)
    });
    return newNote;
  } catch (error) {
    console.error('Error adding custom note:', error);
    throw new Error('Failed to add custom note. Please try again.');
    }
};

export const removeCustomNote = async (projectId: string, noteId: string) => {
  try {
    const projectRef = doc(firestore, 'projects', projectId);
    const projectSnap = await getDoc(projectRef);
    const projectData = projectSnap.data() as ProjectData;
    if (projectData && projectData.customNotes) {
      const updatedNotes = projectData.customNotes.filter(note => note.id !== noteId);
      await updateDoc(projectRef, {
        customNotes: updatedNotes
      });
    } else {
      throw new Error('Project data or custom notes not found');
    }
  } catch (error) {
    console.error('Error removing custom note:', error);
    throw new Error('Failed to remove custom note. Please try again.');
  }
};

export const updateProjectStorageUsage = async (projectId: string, oldContent: ContentItem[], newContent: ContentItem[]) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be authenticated to update project storage usage');
    }

    const projectRef = doc(firestore, 'projects', projectId);
    const userRef = doc(firestore, 'users', user.uid);

    const oldStorageUsage = calculateStorageUsage(oldContent);
    const newStorageUsage = calculateStorageUsage(newContent);
    const storageDifference = newStorageUsage - oldStorageUsage;

    await updateDoc(userRef, {
      storageUsage: increment(storageDifference)
    });

    await updateDoc(projectRef, {
      content: newContent,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error updating project storage usage:', error);
    throw new Error('Failed to update project storage usage. Please try again.');
  }
};