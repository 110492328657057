import React, { useState, useEffect } from 'react';
import { firestore } from '../services/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useAdmin } from '../contexts/AdminContext';

interface Permissions {
  basicUserProjectLimit: number;
  basicUserStorageLimit: number;
  paidUserProjectLimit: number;
  paidUserStorageLimit: number;
}

const defaultPermissions: Permissions = {
  basicUserProjectLimit: 2,
  basicUserStorageLimit: 20,
  paidUserProjectLimit: 10,
  paidUserStorageLimit: 100
};

const ManagePermissions: React.FC = () => {
  const [permissions, setPermissions] = useState<Permissions>(defaultPermissions);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isSuperAdmin } = useAdmin();

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!isSuperAdmin) {
        setError('You do not have permission to view this page.');
        setLoading(false);
        return;
      }

      try {
        const permissionsRef = doc(firestore, 'settings', 'permissions');
        const permissionsSnap = await getDoc(permissionsRef);
        if (permissionsSnap.exists()) {
          setPermissions(permissionsSnap.data() as Permissions);
        } else {
          console.log('No permissions document found, using default values.');
        }
      } catch (err) {
        console.error('Error fetching permissions:', err);
        setError('Failed to fetch permissions. Using default values.');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [isSuperAdmin]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPermissions(prev => ({ ...prev, [name]: parseInt(value) }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSuperAdmin) {
      try {
        const permissionsRef = doc(firestore, 'settings', 'permissions');
        await setDoc(permissionsRef, permissions);
        alert('Permissions updated successfully');
      } catch (err) {
        console.error('Error updating permissions:', err);
        setError('Failed to update permissions. Please try again.');
      }
    }
  };

  if (loading) return <div>Loading permissions...</div>;
  if (error) return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
  if (!isSuperAdmin) return <Alert variant="destructive"><AlertDescription>Access Denied</AlertDescription></Alert>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Permissions</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(permissions).map(([key, value]) => (
          <div key={key}>
            <label className="block mb-2">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
            <Input
              type="number"
              name={key}
              value={value}
              onChange={handleInputChange}
            />
          </div>
        ))}
        <Button type="submit">Update Permissions</Button>
      </form>
    </div>
  );
};

export default ManagePermissions;